<template>
  <div>
  <v-app-bar
    app
    elevate-on-scroll
    color="white"
    height="72px"
  >
    <v-container class="py-0 fill-height">
      <a href="/">
        <v-img
          class="mx-2"
          src="@/assets/images/logo-sg.png"
          max-width="260"
          contain
        ></v-img>
      </a>
      <a>
        <v-img
          class="mx-2"
          src="@/assets/images/logo-sg-flag.png"
          max-width="40"
          contain
        ></v-img>
      </a>
      <v-spacer></v-spacer>
      <v-btn
        v-for="link in links"
        :key="link.path"
        :to="link.path"
        text
        class="mx-1"
        active-class="nav-btn-active"
      >
        {{ link.name }}
      </v-btn>
    </v-container>
  </v-app-bar>
  <v-container>
    <v-row>
    </v-row>
  </v-container>
  </div>
</template>
<script>

export default {
  components: {},
  data: () => ({
    iframe: {
      pvUrl: process.env.VUE_APP_PV_URL,
      fuelUrl: process.env.VUE_APP_FUEL_URL,
      height: 862,
      width: 1048
    },
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
    links: [
      {
        name: 'PV Ersatzabgabe',
        path: '/pv-ersatzabgabe'
      },
      {
        name: 'Gewährleitung Biobrennstoffe',
        path: '/gewaehrleistung-biobrennstoffe'
      }
    ],
    sheet: false
  }),
  computed: {
  }
}

</script>
<style>
iframe {
  overflow: hidden;
}
</style>
